<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Advanced Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      value: {
        title: "Explorer",
        desc: "Discovering new species!",
      },
      options: [
        {
          title: "Space Pirate",
          desc: "More space battles!",
        },
        {
          title: "Merchant",
          desc: "PROFIT!",
        },
        {
          title: "Explorer",
          desc: "Discovering new species!",
        },
        {
          title: "Miner",
          desc: "We need to go deeper!",
        },
      ],
      // tableData: tableData,

      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [5, 25, 50, 100],
      filter: null,
      filterOn: [],
      rowsTest: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "userId",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "familyName",
          sortable: true,
        },
        {
          key: "roleName",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "branchName",
          sortable: true,
        },
        {
          key: "mobilePhone",
          sortable: true,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsTest.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.rowsTest.length;
  },
  created() {
    this.getData();
    // this.select()
  },
  methods: {
    customLabel({ userId, email }) {
      return `${userId} – ${email}`;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/users", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: [1],
          },
        })
        .then((response) => {
          this.rowsTest = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsTest) {
      this.selected = rowsTest;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    testing(rowsTest) {
      this.userId = rowsTest;
      this.overlayFlag = true;
      useNetw
        .delete("api/user/delete", {
          data: {
            userId: 7777,
          },
        })
        .then(() => {
          Swal.fire(appConfig.swal.title.deleterSuccess);
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
      // this.userId = JSON.stringify(this.$refs.selectableTable)
      // const index = this.rowsTest.findIndex(row => row.userId === userId)
      // alert(this.selected)
    },
    testing2(rowsTest) {
      this.userId = rowsTest;
      alert(this.userId);
    },
    // selectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.selectRow(2)
    // },
    // unselectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.unselectRow(2)
    // }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <h4 class="card-title">Data Table</h4>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>


                    <label class="d-inline-flex align-items-center">
                      Search:
                      <multiselect
                        v-model="brandId"
                        label="nameTh"
                        track-by="nameTh"
                        :options="masterBrand"
                        :custom-label="customLabel"
                        :show-labels="false"
                      >
                        <template slot="singleLabel" slot-scope="props"
                          ><span class="option__userId"
                            ><span class="option__email">{{
                              props.option.nameTh
                            }}</span></span
                          ></template
                        >
                        <template slot="option" slot-scope="props"
                          >
                          <div class="option__desc"><span class="option__small">{{
                              props.option.nameTh
                            }}</span>
                          </div>
                        </template>
                      </multiselect>
                    </label>

                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="rowsTest"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <template v-slot:cell(action)="rowsTest">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'detailmycars',
                            params: { userId: rowsTest.item.userId },
                          }"
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="testing(rowsTest.item.userId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </b-table>
                <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p>
              </div>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ rowsperPage }} หน้า {{ rowsPage }} of
                    {{ rowsTotalpages }} total {{ totalRecords }}
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
